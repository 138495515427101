@use "../variables/bp" as bp;
@use "../variables/padding" as padding;
@use "../variables/color" as color;

:root {
  @each $key, $value in bp.$bp-size {
    --bp-size-#{$key}: #{$value};
  }

  @each $key, $value in padding.$bp-padding {
    --bp-padding-#{$key}: #{$value};
  }

  @each $key, $value in color.$list {
    --color-#{$key}: #{$value};
  }
}
