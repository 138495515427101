@forward "./lib/reset";
@forward "./utility/root";

@forward "./parts/container";

body {
  font-family: Lucida Grande, Hiragino Kaku Gothic ProN, Meiryo, sans-serif;
  min-height: 100vh;
  background-color: #f7f8fa;

  line-height: 1.35;
}