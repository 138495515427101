@charset "utf-8";

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
}

body {
	font-family: 'Lucida Grande','Hiragino Kaku Gothic ProN',Meiryo, sans-serif;
	line-height:1;
}

ol, ul {
	list-style:none;
}

a {
	font-size:100%;
	text-decoration: none;
}

table {
	border-collapse:collapse;
	border-spacing:0;
}

input, select {
	vertical-align:middle;
}

img {
	vertical-align: bottom;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
	display:block;
}

blockquote, q {
	quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}

ins {
	color:#000;
	margin-top: 16px;
	text-decoration:none;
}

mark {
	background-color:#ff9;
	color:#000;
	font-style:italic;
	font-weight:bold;
}

del {
	text-decoration: line-through;
}

abbr[title], dfn[title] {
	border-bottom:1px dotted;
	cursor:help;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
	border-radius: 0;
	-webkit-box-sizing: content-box;
	-webkit-appearance: button;
	appearance: button;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
	display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
	outline-offset: -2px;
}