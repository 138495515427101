/**
 * |==================================================================
 * | reset.scss
 * | リセット関連のスタイルはこちらに定義してください。
 * |==================================================================
 */

/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/

html {
  color: #000;
  background: #fff;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q::before,
q::after {
  content: "";
}

abbr,
acronym {
  font-variant: normal;
  border: 0;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

/* #yui3-css-stamp.cssreset{display:none} */

// edit
*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: #000;
}

a,
a:hover {
  text-decoration: none;
}

select {
  width: 100%;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  cursor: pointer;
  background: transparent;
  background-image: none;
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
}
