/* stylelint-disable color-hex-length */
$basic-color: #265a96;
$point-color: #ffd41f;
$base-color: #f7f8fa;
$typograph-base-1: #333333;
$typograph-base-2: #666666;
$typograph-base-3: #999999;
$typograph-heading-1: #265a96;
$typograph-heading-2: #8c9eaf;
$typograph-warning-1: #e35252;
$stroke-1: #dadada;
$stroke-2: #ededed;
/* stylelint-enable color-hex-length */
$list: (
  basic-color: $basic-color,
  point-color: $point-color,
  base-color: $base-color,
  typograph-base-1: $typograph-base-1,
  typograph-base-2: $typograph-base-2,
  typograph-base-3: $typograph-base-3,
  typograph-heading-1: $typograph-heading-1,
  typograph-heading-2: $typograph-heading-2,
  typograph-warning-1: $typograph-warning-1,
  stroke-1: $stroke-1,
  stroke-2: $stroke-2,
);
