@use "../variables/query" as query;

@mixin min-mq($breakpoint: md) {
  @media #{query.get-query-min($breakpoint)} {
    @content;
  }
}

@mixin max-mq($breakpoint: md) {
  @media #{query.get-query-max($breakpoint)} {
    @content;
  }
}
