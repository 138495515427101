@use "../variables/bp"as bp;
@use "../variables/padding"as padding;
@use "../item/responsive"as responsive;

@mixin container {
  @include containerParent;

  &__inner {
    @include containerInner;
  }
}

@mixin containerParent {
  display: flex;
  justify-content: center;

}

@mixin containerInner {
  width: 100%;
  max-width: #{bp.$max-container-size + (padding.$lg * 2)};
  padding-right: #{padding.$xs};
  padding-left: #{padding.$xs};

  @each $key,
  $_ in padding.$bp-padding {
    @include responsive.min-mq($key) {
      padding-right: padding.get-bp-padding($key);
      padding-left: padding.get-bp-padding($key);
    }
  }

}